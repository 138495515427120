
// AOS Effects ================================

$(document).on("turbolinks:load", function(){
  // var AOS = require('aos');
  AOS.init();
  $(".accordion-channel .set-channel > a").on("click", function() {
  if ($(this).hasClass("active")) {
    $(this).removeClass("active");
    $(this).siblings(".content-channel").slideUp(200).removeClass("animation-table");
  }
  else {
    $(".set-channel > a i").removeClass("fa-minus").addClass("fa-plus");
    $(".set-channel > a").removeClass("active");
    $(this).addClass("active");
    $(".content-channel").slideUp(200);
    $(this).siblings(".content-channel").slideDown(200).addClass("animation-table");
  }
  });

  $(".accordian-tabs .set > a").on("click", function() {
  if($(this).hasClass("active-tab")) {
    $(this).removeClass("active-tab");
    $(this).siblings(".content").slideUp(200);
  }
  else {
    $(".accordian-tabs .set > a").removeClass("active-tab");
    $(this).addClass("active-tab");
    $(".content").slideUp(200);
    $(this).siblings(".content").slideDown(200);
  }
  });
  var nav = $('#multiple_menu > ul > li');
  nav.find('li').hide();
  nav.click(function () {
    nav.not(this).find('li').hide();
    $(this).find('li').slideToggle();
  });
  $(function() {  
    $('#multiple_menu input').click(function () { 
    $('#multiple_menu ul').slideToggle() 
  });
  });
});
// $(function() { AOS.init(); }); window.addEventListener('load', AOS.refresh);
// $(document).ready(function(){
//   AOS.init({duration: 1000});
// });
// ================================
// AOS Effects ================================
// ================================
$(document).on('click', '.contact-us-button a', function(){
  $(".contact-us-popupop").addClass("transform-120");
  $(".contact-us-button").addClass("contact-hide");
});
$(document).on('click', '.close-contact-popup', function(){
  $(".contact-us-popupop").removeClass("transform-120");
  $(".contact-us-button").removeClass("contact-hide");

});
// ================================
$(window).scroll(function() {
  var $height = $(window).scrollTop();
  if($height > 20) {
    $('.heading-arrow a').addClass('scale-one');
  }
  else {
    // $('#header').removeClass('active');
  }
});
// ================================
$(document).on('click', '.open-menu .fa-bars', function(){
  $(".open-menu .fa-bars").addClass("scale-zero");
  $(".open-menu .fa-close").removeClass("scale-zero");
  $(".open-menu-dropdown").slideDown('fast');
});
$(document).on('click', '.open-menu .fa-close', function(){
  $(".open-menu .fa-close").addClass("scale-zero");
  $(".open-menu .fa-bars").removeClass("scale-zero");
  $(".open-menu-dropdown").slideUp("fast");
});
// Accordian ==========================

// Accordian ==========================

// Menu Down ==========================
